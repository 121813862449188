import React from "react"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import Audio from "../../../components/audio/audio"
import hutAudio from "./hut-audio.mp3"
import gif from "./fictionGif.mp4"

const hutFiction = (
  <>
    <video
      autoPlay
      loop
      muted
      playsInline
      src={gif}
      style={{ width: "100%" }}
    ></video>
    <div className="u-side-pad u-bottom-pad">
      <h3 className="heading--big">The Case of Koro’s Stone</h3>
      <h4 className="heading--small">By Renée</h4>
      <p>
        Hana had not intended to practice her following and observation skills
        today but when she saw Ra Rolleson pushing the scooter very quickly –
        almost running – she did a u–turn and followed him.{" "}
      </p>
      <p>
        Could there be two red scooters with the same little bear hanging from
        the handle?{" "}
      </p>
      <p>
        Alice Cain said in her blog,{" "}
        <em>
          Becoming a Private Eye, ‘If the odds are questionable, its worth
          investigating.’
        </em>
      </p>
      <p>
        Hana knew about this hut because her Nan had told her. He Kākano. The
        seed pod. A place of beginnings, a place of safety. She’d told Nan about
        wanting to be a PI and Nan has said, ‘Good one.’ Then she said, ‘You’ll
        need someone to report to so you can report to me.’
      </p>
      <p>
        Hana crouched down behind the clump of wavy grasses at the side the hut.
      </p>
      <p>
        She should look up grasses. They all looked the same but she was pretty
        sure there were differences.{" "}
      </p>
      <p>
        The wooden outside of He Kākano was weathered to the same pale
        bluey–grey colour of the stones on the beach, which today merged with
        the grey of the sky so if Hana shut her eyes to{" "}
        <em>fix the impression</em> (as instructed in chapter two), all she
        could see was a grey blob. She opened her eyes, smiled at the gulls
        swooping and diving, calling <em>where where</em> as they looked for
        their breakfast. Probably lots of different kinds of gulls too. Alice
        Cain was right.{" "}
      </p>
      <p>
        <em>
          A Private Investigator’s job is never finished. Always something new
          to learn.
        </em>
      </p>
      <p>
        First aid skills, observation, collecting facts, processing facts,
        online expertise, self–defence.{" "}
      </p>
      <p>
        Should she go and knock on the hut door, ask Ra about the scooter? She
        was only up to part six of <em>Becoming a Private Eye</em> – hadn’t got
        to the chapter, <em>‘Approaching a Suspect’</em>, yet.
      </p>
      <p>
        Over the rise of the dunes came a boy in jeans, red jersey, track shoes.
      </p>
      <p>Jase Morris. </p>
      <p>So the trolley was definitely his. </p>
      <p>Jase walked to the door of the hut and knocked.</p>
      <p>
        The door opened immediately like Ra had been waiting for that knock.{" "}
      </p>
      <p>
        ‘I’ve come for my scooter.’ Jase was trying to sound cool and firm but
        Hana thought he sounded nervous too.
      </p>
      <p>
        Ra said, ‘Give me back my stone and I’ll give you back your trolley.’
      </p>
      <p>
        ‘Millions of them out there.’ Jase nodded towards the beach. ‘Take your
        pick.’
      </p>
      <p>‘My one’s special.’</p>
      <p>
        Jase shrugged. ‘That’s where I chucked it. Bet you can’t even tell which
        one. That’s how special it is.’{" "}
      </p>
      <p>
        He got out his phone, said, ‘I’m gonna count to five. If you haven’t
        given me my trolley back by then I’m ringing the cops.’
      </p>
      <p>
        Ra stared at Jase. ‘Last time my Koro was on the beach, he gave me that
        stone. You throw away the stone, you throw away my Koro. Give me back
        the stone, I’ll give you back your scooter.’
      </p>
      <p>Jase shrugged, lifted his phone.</p>
      <p>
        Hana stood up, walked towards the boys. Alice Cain said,{" "}
        <em>‘A PI has to deal with anything the job throws at you.’</em>
      </p>
      <p>
        ‘I’m Hana,’ she said. ‘I’m good at finding things. She looked at Jase.
        ‘Where did you throw it?’
      </p>
      <p>The two boys stared at her then Jase shrugged. </p>
      <p>Habit, thought Hana.</p>
      <p>
        Jase sighed but he walked around to the back of Te Kākano, pointed to
        the beach.{" "}
      </p>
      <p>‘Describe it to me,’ said Hana to Ra.</p>
      <p>‘Size of my palm, round, kind of flat.’</p>
      <p>‘Any special marks?’ </p>
      <p>Ra’s turn to shrug. ‘Black specks on one side?’</p>
      <p>
        ‘Okay,’ said Hana, ‘We’ll divide the area into three and we can each
        take a third.’
      </p>
      <p>
        ‘Huh,’ said Jase, ‘you’re crazy. I don’t even know what I’m looking
        for.’
      </p>
      <p>
        ’A stone the size of your palm, flat, round, with a few black flecks on
        one side.’{" "}
      </p>
      <p>Jase looked up at the sky and rolled his eyes.</p>
      <p>So the three of them walked down to the beach. </p>
      <p>‘Where?’</p>
      <p>Jase thought for a minute, pointed. </p>
      <p>
        ‘Okay,’ said Hana, ‘Ra you take the middle, Jase take the other side,
        I’ll take this side. Start from the sea and work up to the dunes.’
      </p>
      <p>
        The boys stared at her like – <em>who did she think she was?</em>
      </p>
      <p>
        ‘Look,’ said Hana, ‘If you know where a missing object is then its
        simply a matter of finding it, right?’{" "}
      </p>
      <p>Jase shrugged.</p>
      <p>Hana thought someone should speak to Jase about his shrugging. </p>
      <p>
        Hana walked over to her area. She crouched down, began looking. After a
        second, Jase walked to the other outside bit, got down on his knees and
        stared at the stones. Ra shook his head but walked to the middle, bent
        over, picked up a stone, stared at it, put it down again.
      </p>
      <p>
        Apart from the gulls crying <em>where where</em> and the waves going{" "}
        <em>swish swish</em>, it was very quiet.{" "}
      </p>
      <p>
        Hana kept thinking it has to be here. Jase threw it here, has to be
        here. Her knees and legs got sore so she stood up but it was hard to see
        the stone properly so she crouched down again. At first all the stones
        had looked the same but when you looked closer some were fat, some were
        thin, some had chips out of them, some had streaks of white, some had
        black flecks. It wasn’t until you really started looking that you
        realised just how different they all were.
      </p>
      <p>
        They all kept looking. The clouds got greyer, a few spits of rain fell.
      </p>
      <p>
        <em>
          In some cases failure is part of the job. When this happens we have to
          accept it.
        </em>
      </p>
      <p>
        Just as Hana was about to say, ‘This was a crap idea, we won’t find it,’
        there was a loud shout and Ra held his arm up high, ‘Got it,{" "}
        <em>got</em> it.’ And he jumped and down, holding up the stone and
        laughing.
      </p>
      <p>
        Hana ran over and she and Ra jumped up and down shouting, ‘We got it,{" "}
        <em>we got it</em> and Jase forgot to be cool and jumped up and down and
        shouted too.{" "}
      </p>
      <p>Hana looked at the stone on Ra’s hand. </p>
      <p>Palm size, flat, some back specks.</p>
      <p>
        She didn’t know whether it was Kori’s stone or not but it certainly
        looked like the one Ra had described. What were the odds? Private
        Investigators were always <em>working against the odds</em>, according
        to Alice Cain.
      </p>
      <p>
        Hana watched Jase walk off with his trolley, saw Ra go the other way
        with <em>Koro’s stone</em>, got out her phone.
      </p>
      <p>
        Made a new file. Hana Porohiwi, Private Investigator. Case 1. Koro’s{" "}
      </p>
      <p>Stone. She thought for a moment then added a word.</p>
      <p>
        <em>Solved</em>.
      </p>
      <p>Hana smiled.</p>
      <p>Time to go home. Time to report in to Nan.</p>

      <p>
        <OutboundLink
          href="https://drive.google.com/file/d/1sgJum0PKYGYN7CvgET3hQCKDsEDRguHQ/view"
          target="_blank"
          rel="noopener noreferrer"
          className="big-link"
        >
          Download ‘The Case of Koro’s Stone’ by Renée
        </OutboundLink>
      </p>
      <Audio audio={hutAudio} />
    </div>
    <div className="about-the-author u-side-pad">
      <h3 className="heading--small about-the-author__heading">
        About the author
      </h3>
      <p>
        Renée: Renée (Ngati Kahungunu/Scot) was born grumpy and nothing has
        changed. She is an avid reader, enjoys cooking and gardening. Renée
        always thought she would die at 42 but for some reason this didn’t
        happen. She considers herself very lucky to still have most of her
        marbles and credits reading, writing, walking and teaching, for this.
        Renée has written 8 novels and eighteen plays among which is the
        Wednesday To Come trilogy. Her memoir <em>These Two Hands</em> was
        published by Makaro Press in 2017. Her latest work is a crime novel,
        <em>The Wild Card</em>, publication late October 2019.
      </p>
    </div>
  </>
)
export default hutFiction
