import React, { Fragment } from "react"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const directions = (
  <Fragment>
    <h3>Start Location</h3>

    <p>
      Waitohu Stream and Dune restoration area, Konini St entrance, corner of
      Marine Parade
    </p>

    <h3>Distance to Hut</h3>
    <p>120m</p>

    <h3>Your Directions</h3>

    <p>
      Walk up the path towards the beach from the Konini St entrance. Just
      before you get to the beach turn to your left (south) and see if you can
      spot the hut. Taking care to avoid the spikey wiwi, head off track and
      make your way to the hut. You may see a track where others have walked,
      give it a try.
    </p>

    <h3>Safety Notes</h3>

    <>
      <p>
        Please don’t climb the dune barrier running along the beach directly in
        front of the hut. This is an important protection for the ecosystem and
        is highly fragile. Obey all white tape barriers. The Waitohu Stream and
        Dune care group are a fantastic, dedicated and fiercely protective force
        and they are stewards of this hut. Have respect for their efforts, and
        your own abilities, by working as a team to help guide each other along
        the safest, least destructive route anywhere within the dunes.
      </p>

      <p>
        Dress for the beach and be sun smart. Changing, uneven ground means many
        trip hazards through the dunes. If you happen to see any beach waste,
        washed up from the sea or otherwise, pick it up and dispose snuggly in
        the bin at the start of the track.
      </p>
    </>

    <p>
      <OutboundLink
        href="https://drive.google.com/file/d/1sgJum0PKYGYN7CvgET3hQCKDsEDRguHQ/view"
        target="_blank"
        rel="noopener noreferrer"
        className="big-link"
      >
        Download for offline use
      </OutboundLink>
    </p>
  </Fragment>
)

export default directions
